import AccountCreationComponent from "../../eventer-console/app/account/AccountCreationComponent.tsx";
import {useContext, useEffect} from "react";
import {SessionContext} from "../../eventer-console/app/auth/SessionContext.tsx";
import {useNavigate} from "react-router-dom";
import Session from "../../eventer-console/app/auth/Session.ts";
import {EventerRoutes} from "../../EventerRoutes.ts";
import App from "../../eventer-console/app/App.tsx";

export default function NewAccountRoute() {

    const session = useContext<null | Session>(SessionContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (session != null && !session.isLoggedIn()) {
            // Session initialize, but not logged in ==> redirect to login page
            navigate(EventerRoutes._ROOT)
        }
    }, [session])

    if (session?.isLoggedIn()) {
        return (
            <App session={session} header="Neuen Account erstellen">
                <AccountCreationComponent/>
            </App>
        )
    }
}
