import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {createRoot} from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {StrictMode} from "react";
import RootRoute from "./routes/RootRoute.tsx";
import NewAccountRoute from "./routes/accounts/NewAccountRoute.tsx";
import {SessionProvider} from "./eventer-console/app/auth/SessionContext.tsx";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {de} from "date-fns/locale/de";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {EventerRoutes} from "./EventerRoutes.ts";

// bind error logging at first
// window.onerror = (e: any) => {
//     logError(e, 'console.eventer.app/index.tsx')
// }
// window.onunhandledrejection = (e: any) => {
//     logError(e, 'console.eventer.app/index.tsx')
// }

const theme = createTheme({
    palette: {

        primary: {
            main: "#256377",
            contrastText: "#eee",
        },

        secondary: {
            main: "#fff",
            // contrastText: "#111",
        },

        // text: {
        //     primary: "#111",
        // }

        // There is a bug, IconButton is not using the right text color for its icons
        // https://github.com/mui/material-ui/issues/33054
        // Workaround: use color="info", which will take the "background color".
        // TODO: wait for a bugfix and remove this palette + color="info" hacks
        // info: {
        //     main: "#fff",
        //     // main: "#000",
        // }
    },
})

const node = document.getElementById('root') as HTMLElement
createRoot(node).render(
    <StrictMode>
        <CssBaseline/>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <SessionProvider>
                        <Routes>
                            <Route path={EventerRoutes._ROOT} element={<RootRoute/>}/>
                            <Route path={EventerRoutes.accounts_new} element={<NewAccountRoute/>}/>
                        </Routes>
                        </SessionProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </LocalizationProvider>
    </StrictMode>,
)
