import {Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import * as React from "react";
import Session from "../auth/Session";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {DeleteAccountConfirmationDialog} from "./DeleteAccountConfirmationDialog";
import {useCallback, useState} from "react";
import {eventerConsoleApiClient} from "../../api-client";

export interface AccountMenuProps {
    session: Session;
}

export default function AccountMenu(props: AccountMenuProps) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const [openDeleteAccountConfirmationDialog, setOpenDeleteAccountConfirmationDialog] = useState(false)

    let account = props.session.selectedAccount()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDeleteAccountClick = useCallback(() => {
        setOpenDeleteAccountConfirmationDialog(true)
        handleClose()
    }, [setOpenDeleteAccountConfirmationDialog])

    const handleCloseDeleteAccountComfirmationDialog = useCallback(async (yes: boolean) => {
        if (yes) {
            let accountId = account?.id
            if (accountId) {
                await eventerConsoleApiClient.deleteAccount( { accountId: accountId } )

                // Account has been deleted => full reload
                window.location.reload()
            }
        }
        setOpenDeleteAccountConfirmationDialog(false)
    }, [account?.id, setOpenDeleteAccountConfirmationDialog])

    const handleLogoutClick = useCallback( () => {
        props.session.logout()
        handleClose()
    }, [props.session])

    let accountDeletionEl = null
    let deleteAccountConfirmationDialog = null
    if (account) {
        accountDeletionEl =
            <MenuItem onClick={handleDeleteAccountClick}>
                <ListItemIcon>
                    <DeleteForeverIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Account löschen</ListItemText>
            </MenuItem>
        deleteAccountConfirmationDialog = DeleteAccountConfirmationDialog(
            {
                account: account,
                open: openDeleteAccountConfirmationDialog,
                onClose: handleCloseDeleteAccountComfirmationDialog,
            }
        )
    }

    return (
        <>
            <IconButton
                aria-label="mehr"
                aria-haspopup="true"
                onClick={handleClick}
                // color="secondary.contrastText"
            >
                <MoreVertIcon/>
            </IconButton>

            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                { accountDeletionEl }
                { accountDeletionEl ? <Divider /> : null }
                <MenuItem onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Abmelden</ListItemText>
                </MenuItem>
            </Menu>

            { deleteAccountConfirmationDialog }
        </>
    )
}
