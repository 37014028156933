import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import AccountMenu from "./account/AccountMenu.tsx";
import Session from "./auth/Session.ts";
import React from "react";

interface AppProps {
    session: Session,
    header: string,
    children: React.ReactNode,
}

export default function App(props: AppProps) {

    return (
        <div className="App">
            <AppBar position="static" sx={{boxShadow: 1}} color="secondary">
                <Toolbar>
                    <Box
                        component="img"
                        src="/icons/favicon.svg"
                        alt="Logo"
                        width="32px"
                    />

                    <Typography
                        variant="subtitle1"
                        sx={{
                            flexGrow: 1,
                            paddingLeft: '0.5rem',
                            paddingTop: '0.1rem',
                        }}
                    >
                        :: {props.header}
                    </Typography>

                    <AccountMenu session={props.session}></AccountMenu>
                </Toolbar>
            </AppBar>
            {props.children}
        </div>
    )
}
