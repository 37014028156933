import {eventerConsoleApiClient} from "../api-client.ts";
import {Box, Button, Container, Typography} from "@mui/material";
import {Google} from "@mui/icons-material";

interface SplashScreenProps {
    isLoading?: boolean
}

export default function SplashScreen({isLoading}: SplashScreenProps) {

    const href = eventerConsoleApiClient.apiBaseUrl + "auth/google/oauth/start"

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Container
                sx={{
                    textAlign: 'center',
                }}
            >
                <Box
                    component="img"
                    src="/icons/favicon.svg"
                    alt="Logo"
                    sx={{
                        width: '100px',
                        marginBottom: '0.5rem',
                    }}
                />
                <Typography variant="h5" sx={{marginBottom: '0.5rem'}}>
                    Willkommen bei Eventer
                </Typography>
                <Typography variant="body1" sx={{marginBottom: '6rem'}}>
                    Deine Plattform für Veranstaltungen
                </Typography>
                <Typography variant="body1" sx={{marginBottom: '2rem', color: 'text.secondary'}}>
                    Erstellen - Verwalten - Verteilen - Vernetzen
                </Typography>

                {
                    !isLoading && (
                        <Button
                            href={href}
                            variant="contained"
                            startIcon={<Google/>}
                            sx={{
                                // We should use official Google colors
                                backgroundColor: '#4285F4',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#357ae8',
                                },
                                padding: '0.75rem',
                                paddingLeft: '2rem',
                                paddingRight: '2rem',
                                borderRadius: '8px',
                            }}
                        >
                            Mit Google anmelden
                        </Button>
                    )
                }

            </Container>
        </Box>
    )
}
