import {SessionContext} from "../eventer-console/app/auth/SessionContext.tsx";
import {useContext, useEffect} from "react";
import Session from "../eventer-console/app/auth/Session.ts";
import SplashScreen from "../eventer-console/app/SplashScreen.tsx";
import MainScreen from "../eventer-console/app/MainScreen.tsx";
import {EventerRoutes} from "../EventerRoutes.ts";
import {useNavigate} from "react-router-dom";

export default function RootRoute() {

    const session = useContext<null | Session>(SessionContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (session?.isLoggedIn() && session.selectedAccount() == null) {
            // Logged in but no account yet ==> redirect to account creation
            console.log("Logged in but no account yet ==> redirect to account creation")
            navigate(EventerRoutes.accounts_new)
        }
    }, [session])

    const account = session?.selectedAccount()
    if (session && account) {
        // We have a session and a selected account
        return <MainScreen session={session} account={account}/>
    }

    return <SplashScreen isLoading={session == null}/>
}
