import {Account, UserInfo} from "../../api-client";

class Session {

    // null: not logged in
    readonly id?: string;

    readonly user?: UserInfo;

    readonly accounts: Account[] = [];

    logout(): void {
    }

    constructor(params?: {
        id: string,
        user?: UserInfo,
        accounts?: Account[],
        logout: () => void,
    }) {
        this.id = params?.id
        this.user = params?.user
        this.accounts = params?.accounts || []
        let l = params?.logout
        if (l) {
            this.logout = l
        }
    }

    isLoggedIn(): boolean {
        return this.id !== undefined;
    }

    selectedAccount(): Account | null {
        // TODO: persist this in localStorage
        if (this.accounts.length === 0) {
            return null
        } else {
            return this.accounts[0]
        }
    }
}

export default Session;
