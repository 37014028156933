export interface ErrorResponse {
    readonly errors?: Error[]
}

export interface Error {
    readonly code: string
    readonly message?: string
    readonly parameters?: ErrorParameters
}

export interface ErrorParameters {
    readonly [key: string]: string
}

export interface Account {
    readonly id: string;
    readonly podName: string;
    readonly emailContacts?: EmailContact[];
}

export interface UserInfo {
    readonly id: string
    readonly name?: string
}

export interface Credentials {
    readonly sessionId: string;
    readonly user?: UserInfo;
    readonly accounts?: Account[];
}

export interface GooglePlace {
    readonly id: string;
    readonly name: string;
    readonly address: string;
}

export interface IntegrationSyncInfo {
    readonly is_syncing: boolean;
    readonly last_sync_finished_at?: string;
}

export interface GCalIntegration {
    readonly id: string
    readonly google_calendar_id: string
    readonly google_calendar_hash: string
    readonly google_calendar_name: string
    readonly default_google_place_id?: string
    readonly trusted: boolean
    readonly sync_info: IntegrationSyncInfo
}

export interface EmailContact {
    readonly email: string
    readonly name?: string
}

export interface Email {
    readonly to: EmailContact[];
    readonly subject: string;
    readonly message: string;
}

export interface InfoEmailRequest {
    readonly email_addresses: string[];
    readonly starts_at_min?: Date;
    readonly starts_at_max?: Date;
    readonly updated_since?: Date;
}

enum HttpMethod {
    DELETE = "DELETE",
    GET = "GET",
    POST = "POST",
}

export interface EventerConsoleApiFetchOptions {
    params?: any;
    sessionId?: null | undefined | string;
    method?: HttpMethod;
}

class EventerConsoleApiClient {

    // apiBaseUrl: string =
    //     process.env.NODE_ENV === 'production' ? 'https://api.eventer.app/v1/console/' : 'http://localhost:8888/v1/console/'

    apiBaseUrl: string = 'https://api.eventer.app/v1/console/'


    private async fetch<T>(path: string, options?: EventerConsoleApiFetchOptions): Promise<T> {
        const method = options ? (options!!.method || HttpMethod.POST) : HttpMethod.POST
        let headers = new Headers({
            'Accept': 'application/json',
        })
        if (options && options.sessionId) {
            headers.set('Authorization', `Session ${options.sessionId}`)
        }
        if (method === HttpMethod.POST) {
            headers.set('Content-Type', 'application/json')
        }
        let response = await fetch(`${this.apiBaseUrl}${path}`, {
            headers: headers,
            method,
            body: options ? JSON.stringify(options.params) : '',
        });
        if (!response.ok) {
            // special check for 'unauthorized' with sessionId
            if (response.status === 401 && options && options.sessionId) {
                window.location.href = window.location.origin
            }
            return Promise.reject(response)
        }
        const body = await response.text();
        if (body && body.length > 0) {
            return JSON.parse(body);
        } else {
            return {} as T;
        }
    }

    async parseErrorResponse(response: Response) {

        const json = await response.json()

        let errors: Error[] = Array<Error>()
        Object.keys(json).forEach((property) => {
            if (property === 'errors') {
                let value = json[property]
                if (Array.isArray(value)) {

                    value.forEach((errorJson) => {
                        let code = errorJson["code"]
                        if (code.length === 0) {
                            // Seems to be error
                            return
                        }

                        // TODO: parse parameters

                        errors.push({
                            code: code,
                            message: errorJson["message"],
                        })
                    })
                }
            }
        })

        if (errors.length === 0) {
            errors.push(
                {
                    code: "unknown",
                }
            )
        }

        return {
            errors: errors,
        }
    }

    async sessionAuth(
        params: { sessionId: string; },
    ): Promise<Credentials> {
        return this.fetch<Credentials>('auth/session', {
            params: {sessionId: params.sessionId}
        })
    }

    async logout(params: { sessionId: string; }) {
        return this.fetch<undefined>('auth/logout', {sessionId: params.sessionId})
    }

    async createAccount(params: { podName: string; }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `accounts`,
            {
                sessionId: sessionId,
                params: {
                    podName: params.podName,
                }
            },
        )
    }

    async deleteAccount(params: {
        accountId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `accounts/${params.accountId}`,
            {
                method: HttpMethod.DELETE,
                sessionId: sessionId,
            },
        )
    }

    async searchPlaces(
        params: { q: string; }
    ): Promise<GooglePlace[]> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GooglePlace[]>(
            `places/search?q=${params.q}`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async testGCalIntegration(params: {
        accountId: string;
        gCalId: string;
        defaultGooglePlaceId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `accounts/${params.accountId}/integrations/gcal/test`,
            {
                sessionId: sessionId,
                params: {
                    "google_calendar_id": params.gCalId,
                    "default_google_place_id": params.defaultGooglePlaceId,
                },
            },
        )
    }

    async getGCalIntegrations(params: {
        accountId: string;
    }): Promise<GCalIntegration[]> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration[]>(
            `accounts/${params.accountId}/integrations/gcal`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async getGCalIntegration(params: {
        accountId: string;
        id: string;
    }): Promise<null | GCalIntegration> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal/${params.id}`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async addGCalIntegration(params: {
        accountId: string;
        gCalId: string;
        defaultGooglePlaceId: string;
    }): Promise<GCalIntegration> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal`,
            {
                sessionId: sessionId,
                params: {
                    "google_calendar_id": params.gCalId,
                    "default_google_place_id": params.defaultGooglePlaceId,
                },
            },
        )
    }

    async syncGCalIntegration(params: {
        accountId: string;
        gCalId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalId}/sync`,
            {
                sessionId: sessionId,
            },
        )
    }

    async deleteGCalIntegration(params: {
        accountId: string;
        gCalId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalId}`,
            {
                method: HttpMethod.DELETE,
                sessionId: sessionId,
            },
        )
    }

    async addEmailContacts(params: {
        accountId: string;
        emailContacts: EmailContact[];
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Account>(
            `accounts/${params.accountId}/add_email_contacts`,
            {
                sessionId: sessionId,
                params: {
                    email_contacts: params.emailContacts,
                }
            },
        )
    }

    async removeEmailContacts(params: {
        accountId: string;
        emailAddresses: String[];
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Account>(
            `accounts/${params.accountId}/remove_email_contacts`,
            {
                sessionId: sessionId,
                params: {
                    email_addresses: params.emailAddresses,
                }
            },
        )
    }

    async testInfoEmail(params: {
        accountId: string;
        gCalIntegrationId: string;
        infoEmailRequest: InfoEmailRequest;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Email>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalIntegrationId}/mails/test-info-email`,
            {
                sessionId: sessionId,
                params: params.infoEmailRequest,
            },
        )
    }

    async sendInfoEmail(params: {
        accountId: string;
        gCalIntegrationId: string;
        infoEmailRequest: InfoEmailRequest;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalIntegrationId}/mails/send-info-email`,
            {
                sessionId: sessionId,
                params: params.infoEmailRequest,
            },
        )
    }
}


const eventerConsoleApiClient = new EventerConsoleApiClient();
export {eventerConsoleApiClient};
