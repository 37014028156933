import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Stack,
    Typography
} from "@mui/material";
import {Email, GCalIntegration} from "../../../api-client";

export interface SendInfoMailConfirmationDialogProps {
    accountId: string;
    gCalIntegration: GCalIntegration;
    infoEmail?: Email;
    onClose: (yes: boolean) => void;
}

export function SendInfoMailConfirmationDialog(props: SendInfoMailConfirmationDialogProps) {

    let emailEls = props.infoEmail?.to
        .map((emailContact) =>
            <Chip label={emailContact.email} key={emailContact.email} sx={{margin: "2px"}}></Chip>
        )

    return (
        <Dialog open={props.infoEmail !== undefined}>
            <DialogTitle>Soll diese Info E-Mail gesendet werden?</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Box>{emailEls}</Box>
                    <Divider />
                    <Typography sx={{paddingTop: "10px"}}>
                        {props.infoEmail?.subject}
                    </Typography>
                    <Typography component="div" variant="body2">
                        <div dangerouslySetInnerHTML={{ __html: props.infoEmail?.message || "" }}/>
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.onClose(false)
                }}>Abbrechen</Button>
                <Button onClick={() => {
                    props.onClose(true)
                }}>Ja</Button>
            </DialogActions>
        </Dialog>
    )
}
