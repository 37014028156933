import {eventerConsoleApiClient, GCalIntegration} from "../../../api-client";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {formatDateTime} from "../../../../eventer/common/date-util";
import {SendInfoMailDialog} from "./SendInfoMailDialog";


export interface GCalListItemProps {
    accountId: string;
    gCalIntegration: GCalIntegration;
    onUpdate: (gCalIntegration: GCalIntegration) => void;
    onDelete: () => void;
}

export default function GCalListItem(props: GCalListItemProps) {

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

    const [isSyncing, setSyncing] = useState<boolean>(false)

    const sync = useCallback(() => {
        if (isSyncing) {
            return
        }
        setSyncing(true)
        console.log("start syncLoop: ")

        let syncLoop = () => {
            eventerConsoleApiClient.getGCalIntegration(
                {
                    accountId: props.accountId,
                    id: props.gCalIntegration.id,
                }
            ).then((gCalIntegration) => {
                if (gCalIntegration == null) {
                    // TODO: handle => delete
                    return
                }
                if (gCalIntegration.sync_info.is_syncing) {
                    console.log("syncLoop: sync not finished: ", gCalIntegration)
                    setTimeout(syncLoop, 2000)
                } else {
                    props.onUpdate(gCalIntegration)
                    setSyncing(false)
                    console.log("syncLoop: sync finished: ", gCalIntegration)
                }
            })
        }
        syncLoop()
    }, [isSyncing, props])

    const handleSync = useCallback(async () => {
        await eventerConsoleApiClient.syncGCalIntegration(
            {
                accountId: props.accountId,
                gCalId: props.gCalIntegration.id,
            }
        )

        // fake sync client side
        const updatedGCalIntegration = {
            ...props.gCalIntegration,
            sync_info: {
                ...props.gCalIntegration.sync_info,
                is_syncing: true,
            }
        }
        props.onUpdate(updatedGCalIntegration)

        sync()

    }, [props, sync])

    const handleDelete = useCallback(async () => {
        try {
            await eventerConsoleApiClient.deleteGCalIntegration(
                {
                    accountId: props.accountId,
                    gCalId: props.gCalIntegration.id,
                }
            )
        } catch (e) {
            if (e instanceof Response && e.status === 404) {
                // Already deleted, nothing to do
            } else {
                // Handle?
                console.log(e)
                return
            }
        }
        props.onDelete()
    }, [props])

    useEffect(() => {
        if (!isSyncing && props.gCalIntegration.sync_info.is_syncing) {
            sync()
        }
    }, [isSyncing, props.gCalIntegration.sync_info.is_syncing, sync])

    const [openMailDialog, setOpenMailDialog] = useState(false)

    const handleMailDialogClose = useCallback(() => {
        setOpenMailDialog(false)
    }, [setOpenMailDialog])

    let syncInfo;
    let syncButton;
    if (props.gCalIntegration.sync_info.is_syncing) {
        syncInfo = (
            <Typography variant="body2">
                Synchronisierung läuft...
            </Typography>
        )
    } else {
        const lastSyncFinishedAt = props.gCalIntegration.sync_info.last_sync_finished_at
        if (lastSyncFinishedAt) {
            const date = formatDateTime(new Date(Date.parse(lastSyncFinishedAt)))
            syncInfo = (
                <Typography variant="body2">
                    Letzte Synchronisierung: {date}
                </Typography>
            )
        }
    }
    syncButton =
        <Button
            onClick={handleSync}
            disabled={props.gCalIntegration.sync_info.is_syncing}
        >
            Synchronisieren
        </Button>

    return (
        <>
            <Card variant="outlined" sx={{margin: 2}}>
                <CardContent>
                    <Typography variant="h6">
                        {props.gCalIntegration.google_calendar_name}
                    </Typography>
                    {syncInfo}
                </CardContent>
                <CardActions sx={{ float: 'right' }}>
                    {syncButton}
                    <Button onClick={() => setOpenDeleteConfirmation(true)}>Entfernen</Button>
                    <Dialog open={openDeleteConfirmation}>
                        <DialogTitle>Kalendar wirklich entfernen?</DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setOpenDeleteConfirmation(false)} autoFocus>Abbrechen</Button>
                            <Button onClick={handleDelete}>
                                Löschen
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Button onClick={() => setOpenMailDialog(true)}>E-Mail senden</Button>
                </CardActions>
            </Card>

            <SendInfoMailDialog
                accountId={props.accountId}
                cGalIntegration={props.gCalIntegration}
                open={openMailDialog}
                onClose={handleMailDialogClose}
            />
        </>
    )
}
