import GCalList from "./integrations/gcal/GCalList";
import Session from "./auth/Session.ts";
import {Account} from "../api-client.ts";
import App from "./App.tsx";

interface MainScreenProps {
    session: Session,
    account: Account,
}

export default function MainScreen({session, account}: MainScreenProps) {

    return (
        <App session={session} header={`Google Kalender`}>
            <GCalList accountId={account.id}/>
        </App>
    )
}
