import {createContext, ReactNode, useEffect, useState} from 'react'
import Session from "./Session";
import {eventerConsoleApiClient} from "../../api-client";
import {useSearchParams} from "react-router-dom";

export const SessionContext = createContext<null | Session>(null)

interface SessionProviderProps {
    children: ReactNode | ReactNode[]
}

export const SessionProvider = (props: SessionProviderProps) => {

    const [session, setSession] = useState<null | Session>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        let active = true

        console.log("SessionProvider: useEffect called")

        if (session != null) {
            console.log("We already have a valid session, skipping session validation")
            return
        }

        const updateSession = (session: null | Session) => {
            const sessionId = session?.id;
            if (sessionId) {
                localStorage.setItem('sessionId', sessionId);
            } else {
                localStorage.removeItem('sessionId');
            }
            const sessionIdFromQuery = searchParams.get('sessionId')
            if (sessionIdFromQuery) {
                setSearchParams((params) => {
                    params.delete('sessionId')
                    return params
                })
            }
            setSession(session)
        }

        const logout = async () => {
            console.log("logout called")
            const storedSessionId = localStorage.getItem('sessionId')
            if (storedSessionId != null) {
                try {
                    await eventerConsoleApiClient.logout({sessionId: storedSessionId})
                } catch (err) {
                    console.log(`error on logout (${storedSessionId}): ${err}`)
                }
            }
            updateSession(new Session())
        }

        const sessionIdFromQuery = searchParams.get('sessionId')
        if (sessionIdFromQuery) {
            console.log("Got 'sessionId' from query: ", sessionIdFromQuery)
            // localStorage.setItem('sessionId', sessionIdFromQuery)
            // setSearchParams((params) => {
            //     params.delete('sessionId')
            //     return params
            // })
            // return
        }

        const sessionId = sessionIdFromQuery || localStorage.getItem('sessionId')
        console.log("sessionId: ", sessionId)
        if (sessionId) {
            // validate session
            eventerConsoleApiClient.sessionAuth({sessionId: sessionId})
                .then((authResponse) => {
                    console.log(`got auth response, active=${active}: `, authResponse)
                    if (!active) {
                        return
                    }
                    updateSession(
                        new Session({
                            id: authResponse.sessionId,
                            user: authResponse.user,
                            accounts: authResponse.accounts,
                            logout: logout,
                        })
                    )
                })
                .catch((err) => {
                    console.log(`error on validating session, active=${active}:`, err)
                    if (!active) {
                        return
                    }
                    updateSession(new Session())
                })
        } else {
            updateSession(new Session())
        }

        return () => {
            active = false
        }
    }, [searchParams, session, setSearchParams])

    return <SessionContext.Provider value={session}>{props.children}</SessionContext.Provider>
}
