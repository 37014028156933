import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Typography
} from "@mui/material";
import {Account} from "../../api-client";

export interface DeleteAccountConfirmationDialogProps {
    account: Account;
    open: boolean;
    onClose: (yes: boolean) => void;
}

export function DeleteAccountConfirmationDialog(props: DeleteAccountConfirmationDialogProps) {

    return (
        <Dialog open={props.open}>
            <DialogTitle>Soll dieser Account "{props.account.podName}" wirklich gelöscht werden?</DialogTitle>
            <DialogContent>
                <Typography component="div" variant="body2">
                    Alle Daten werden unwiderruflich gelöscht!
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.onClose(false)
                }}>Abbrechen</Button>
                <Button onClick={() => {
                    props.onClose(true)
                }}>Ja</Button>
            </DialogActions>
        </Dialog>
    )
}
